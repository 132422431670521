export const CONTENT_API_V3 = 'CONTENT_API_V3';

export const DEFAULT_CONTENT_API = 'default';
export const ALPHA_CONTENT_API = 'alpha';
export const BETA_CONTENT_API = 'beta';

export default {
  name: CONTENT_API_V3,
  variations: [DEFAULT_CONTENT_API, BETA_CONTENT_API],
  version: 4,
};
